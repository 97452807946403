var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container jinwei-video-square" },
    [
      _c(
        "el-tabs",
        {
          attrs: { "tab-position": "left" },
          on: { "tab-click": _vm.tabChange },
          model: {
            value: _vm.defaultTabs,
            callback: function ($$v) {
              _vm.defaultTabs = $$v
            },
            expression: "defaultTabs",
          },
        },
        _vm._l(_vm.FloorList, function (item) {
          return _c(
            "el-tab-pane",
            { key: item.name, attrs: { label: item.label, name: item.name } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _vm.defaultTabs === item.name
                    ? _vm._l(
                        _vm.Sources[_vm.defaultTabs],
                        function (item, index) {
                          return _c(
                            "el-col",
                            {
                              key: index,
                              staticClass: "video-line",
                              attrs: { span: 12 },
                            },
                            [_c("videoComponent", { attrs: { datas: item } })],
                            1
                          )
                        }
                      )
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }