export const FloorList = [
  { label: '23F-电梯机房', name: '23FD' },
  { label: '23F-机房', name: '23F' },
  { label: '22F-机房', name: '22F' },
  { label: '21F-机房', name: '21F' },
];
export const Sources = {
  '23FD': [
    {
      position: '23楼电梯机房1',
      source:
        `${process.env.VUE_APP_VIDEO_PATH}/34020000001320000127@34020000001320000127.flv`,
    },
    {
      position: '23楼电梯机房2',
      source:
        `${process.env.VUE_APP_VIDEO_PATH}/34020000001320000129@34020000001320000129.flv`,
    },
  ],
  '23F': [
    {
      position: '23F-机房01_10.123',
      source:
        `${process.env.VUE_APP_VIDEO_PATH}/34020000001320000123@34020000001320000123.flv`,
    },
    {
      position: '23F-机房02_10.124',
      source:
        `${process.env.VUE_APP_VIDEO_PATH}/34020000001320000124@34020000001320000124.flv`,
    },
    {
      position: '23F-机房03_10.125',
      source:
        `${process.env.VUE_APP_VIDEO_PATH}/34020000001320000125@34020000001320000125.flv`,
    },
    {
      position: '23F-机房04_10.126',
      source:
        `${process.env.VUE_APP_VIDEO_PATH}/34020000001320000126@34020000001320000126.flv`,
    },
  ],
  '22F': [
    {
      position: '22F-机房01_9.73',
      source:
        `${process.env.VUE_APP_VIDEO_PATH}/34020000001320000073@34020000001320000073.flv`,
    },
    {
      position: '22F-机房02_9.74',
      source:
        `${process.env.VUE_APP_VIDEO_PATH}/34020000001320000074@34020000001320000074.flv`,
    },
  ],
  '21F': [
    {
      position: '21F-机房_255.241',
      source:
        `${process.env.VUE_APP_VIDEO_PATH}/34020000001320000241@34020000001320000241.flv`,
    },
  ],
};
