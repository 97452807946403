<template>
  <div class="app-container jinwei-video-square">
    <el-tabs v-model="defaultTabs" tab-position="left" @tab-click="tabChange">
      <el-tab-pane
        v-for="item in FloorList"
        :key="item.name"
        :label="item.label"
        :name="item.name"
      >
        <el-row :gutter="20">
          <!-- 只渲染当前页签的，不然会把所有player都初始化 -->
          <template v-if="defaultTabs === item.name">
            <el-col
              class="video-line"
              :span="12"
              v-for="(item, index) in Sources[defaultTabs]"
              :key="index"
            >
              <videoComponent :datas="item" />
            </el-col>
          </template>
        </el-row>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import videoComponent from "./videoComponent.vue";
import { Sources, FloorList } from "./JinweiVideoSource.js";
export default {
  name: "jinweiVideoSquare",
  components: {
    videoComponent,
  },
  data() {
    return {
      FloorList,
      Sources,
      defaultTabs: "23FD",
    };
  },
  created() {
    console.log("Sources", Sources);
    console.log("floorList", FloorList);
  },
  methods: {
    tabChange(ev) {
      console.log(ev.paneName);
    },
  },
};
</script>

<style lang="less" scoped>
.jinwei-video-square {
  .video-line {
    margin-bottom: 40px;
  }
  .el-row {
    padding-left: 10px;
  }
}
</style>